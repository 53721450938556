module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#17365c","cache_busting_mode":"none","display":"standalone","icon":"logo.png","icons":[{"sizes":"36x36","src":"/icons/icon-36x36.png","type":"image/png"},{"sizes":"48x48","src":"/icons/icon-48x48.png","type":"image/png"},{"sizes":"72x72","src":"/icons/icon-72x72.png","type":"image/png"},{"sizes":"96x96","src":"/icons/icon-96x96.png","type":"image/png"},{"sizes":"144x144","src":"/icons/icon-144x144.png","type":"image/png"},{"sizes":"192x192","src":"/icons/icon-192x192.png","type":"image/png"},{"sizes":"256x256","src":"/icons/icon-256x256.png","type":"image/png"},{"sizes":"384x384","src":"/icons/icon-384x384.png","type":"image/png"},{"sizes":"512x512","src":"/icons/icon-512x512.png","type":"image/png"},{"purpose":"maskable","sizes":"512x512","src":"/icons/maskable-icon.png","type":"image/png"},{"sizes":"70x70","src":"/icons/ms-icon-70x70.png","type":"image/png"},{"sizes":"150x150","src":"/icons/ms-icon-150x150.png","type":"image/png"},{"sizes":"310x310","src":"/icons/ms-icon-310x310.png","type":"image/png"}],"name":"Jeremie Rodriguez | CV","start_url":"/","theme_color":"#17365c","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/icons/**"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
